import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { EventContext } from "Components";
import { Layout, BodySection, Container } from "../Layout";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);

  return (
    <Layout showHeader noPadding showFooter preCampaign>
      <StyledBodySection>
        <Container>
          <p>{ReactHtmlParser(event.preCampaign.body)}</p>
        </Container>
      </StyledBodySection>
    </Layout>
  );
};

const StyledBodySection = styled(BodySection)`
  color: #ec1300;
  display: flex;
  height: 100%;
  align-items: center;

  p {
    font-size: 64px;
    font-weight: 700;
  }

  @media (max-width: 950px) {
    padding: 250px 0 200px;
    p {
      font-size: 50px;
    }
  }
`;
