import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

export const Main = ({ children, noPadding, subHeading, showStyle }) => {
  return (
    !!children && (
      <MainWrapper id="main" noPadding={noPadding} showStyle={showStyle}>
        {subHeading && (
          <SubheadingContent>{ReactHtmlParser(subHeading)}</SubheadingContent>
        )}
        {children}
      </MainWrapper>
    )
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  background-color: ${(props) => (props.showStyle ? "#FFEEE6" : "transparent")};
`;

const SubheadingContent = styled.h4`
  text-align: center;
  font-size: clamp(24px, 3vw, 32px);
  margin: 25px 15px 0;

  sup {
    font-size: 50%;
  }

  span {
    font-weight: 300;
    text-transform: inherit;
  }

  span:nth-of-type(1) {
    color: #f6231b;
  }
`;
